import React, { FC, useRef } from 'react';
import { Adaptive } from 'fronton-react';
import { useHamburgerMenuState, useStickyHeader, useCityPickerState } from 'components/header/hooks';
import { HeaderModelData } from 'types/model';
import { useIsCheckout } from 'components/header/hooks/use-is-checkout';
import { OrderStatus } from 'components/order-status';
import { useHideMobileApp } from 'common/hooks/use-hide-mobile-app';
import { cx } from 'linaria';
import { MenuMobile } from '../menu-mobile';
import { headerFullScreen, sticky, isVisible } from './styles';
import { MobileApp } from '../mobile-app';
import { useMobileBottomPadding } from './hooks/use-mobile-bottom-padding';
import { HeaderContent } from './header-content';
import { withContextSetup, withModel } from '../hocs';
import { MainMenu } from '../main-menu';
import { RegionsMenu } from '../regions-menu';
import { useCdpScript } from './hooks/cdp-script';
import { useAppsflyerScript } from './hooks/appsflyer-script/use-appsflyer-script';

type Props = {
  model?: HeaderModelData;
  disableSticky?: boolean;
};

export const HeaderLayout: FC<Props> = ({ model = {} as HeaderModelData, disableSticky }) => {
  const headerContainerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const menuMobileRef = useRef<HTMLDivElement>(null);

  const headerContainerHeight = headerContainerRef?.current?.clientHeight || 0;
  const isStickyVisible = useStickyHeader(headerRef, isVisible, !!disableSticky, 10, headerContainerHeight);

  useMobileBottomPadding(menuMobileRef);

  useCdpScript();

  useAppsflyerScript();

  const isCheckout = useIsCheckout();
  const hamburgerMenuState = useHamburgerMenuState();
  const regionsMenuState = useCityPickerState();
  const isHideMobileApp = useHideMobileApp();

  return (
    <>
      <div ref={headerContainerRef}>
        {!isHideMobileApp && <MobileApp model={model} />}
        <div className={headerFullScreen}>
          <HeaderContent model={model} isStickyVisible={isStickyVisible} />
        </div>
        <div data-component-content="sticky-header" className={cx(headerFullScreen, sticky)} ref={headerRef}>
          <HeaderContent model={model} isSticky isStickyVisible={isStickyVisible} />
        </div>
        {!isCheckout && (
          <>
            <Adaptive to="desktop">
              <MenuMobile model={model} ref={menuMobileRef} />
            </Adaptive>
          </>
        )}
      </div>
      <OrderStatus />
      <MainMenu model={model} {...hamburgerMenuState} />
      <RegionsMenu {...regionsMenuState} model={model} />
    </>
  );
};

export const HeaderComponent = withContextSetup(withModel(HeaderLayout));
